<template>
  <div class="value-chain-container">
    <div v-if="isDeletable" class="delete-button-container">
      <v-icon class="delete-icon" @click="deleteValueChain"
        >mdi-trash-can-outline</v-icon
      >
    </div>
    <div class="value-chain-details-container">
      <div class="value-chain-details">
        <div>{{ item.name }}</div>
        <div>Product type: {{ item.productType }}</div>
        <div>Number of partners: {{ numberOfPartners }}</div>
        <div>{{ item.description }}</div>
        <div class="score-container">
          <div class="score">Transparency {{ transparencyScore }}%</div>
          <div class="score">Circularity - {{ circularityRange }}</div>
        </div>
      </div>
      <div>
        <value-chain-circle
          :title="item.name"
          :values="item.values"
          :radius="150"
        ></value-chain-circle>
      </div>
    </div>
  </div>
</template>

<script>
import ValueChainCircle from "./ValueChainCircle.vue";
import {
  calculateTransparencyScore,
  calculateCircularityRange,
  calculateNumberOfPartners,
} from "../utils/valueChainHelper";
import _ from "lodash";

export default {
  name: "ValueChainItem",
  components: {
    ValueChainCircle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    transparencyScore() {
      return calculateTransparencyScore(this.item.values);
    },
    circularityRange() {
      return _.startCase(calculateCircularityRange(this.item.values));
    },
    numberOfPartners() {
      return calculateNumberOfPartners(this.item.values);
    },
  },
  methods: {
    deleteValueChain() {
      this.$emit("delete", this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.delete-icon {
  cursor: pointer;
}
.delete-icon:hover {
  font-size: 30px;
}
.value-chain-container {
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #eef1fa;
  margin-top: 20px;
}
.value-chain-details-container {
  display: flex;
  justify-content: space-between;
}
.value-chain-details {
  flex: 1;
  text-align: left;
  font-size: 16px;
  margin-right: 10px;
}
.value-chain-details > div:first-child {
  font-weight: bold;
  font-size: 18px;
}
.score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.score {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #c2aae8;
  padding: 10px 20px;
}
</style>
